/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import type { ResourceMetrics } from '@opentelemetry/sdk-metrics';
import type { IExportMetricsServiceRequest } from './types';
import type { OtlpEncodingOptions } from '../common/types';
import { toResourceMetrics } from './internal';

export function createExportMetricsServiceRequest(
  resourceMetrics: ResourceMetrics[],
  options?: OtlpEncodingOptions
): IExportMetricsServiceRequest {
  return {
    resourceMetrics: resourceMetrics.map(metrics =>
      toResourceMetrics(metrics, options)
    ),
  };
}
